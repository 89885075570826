<template>
  <section class="yml">
    <div class="f mb-20 w">
      <div>
        <h1>Ubuntu 20</h1>
        <p>Инструкция как настроить Ubuntu 20</p>
      </div>
      <a class="btn" target="_blank" href="https://cp.sprinthost.ru/customer/boxes/list">Открыть хостинг</a>
    </div>
    <div class="fields w">
      <div class="form-control">
        <label>IP Address</label>
        <input v-model="ip" />
      </div>
      <div class="form-control">
        <label>Username</label>
        <input spellcheck="false" v-model="username" />
      </div>
    </div>
    <div v-for="(commandNodes, cN) of commands" :key="cN" class="action_result" tabindex="0">
      <template v-for="(node, n) of commandNodes">
        <pre :key="`${n}-${node.type}`" v-if="node.type === 'code'" v-highlightjs="node.content"><code class="bash"></code></pre>
        <p :key="`${n}-${node.type}`" v-if="node.type === 'text'" v-html="node.content" />
      </template>
    </div>
    <div class="row">
      <p class="next">Перейти к <router-link to="/services/node_js">настройке бекенда</router-link></p>
      <p class="next">Перейти к <router-link to="/services/nginx">настройке доменов</router-link></p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Deployment',
  data() {
    return {
      ip: '185.185.68.125',
      username: 'yablonev',
    }
  },
  computed: {
    commands() {
      return [
        [
          { type: 'code', content: `ssh root@${this.ip}` },
        ],
        [
          { type: 'code', content: `apt update -y` },
        ],
        [
          { type: 'code', content: `apt install dpkg-dev build-essential gnupg2 git gcc cmake libpcre3 libpcre3-dev zlib1g zlib1g-dev openssl libssl-dev curl unzip -y` },
        ],
        [
          { type: 'code', content: `curl -L https://nginx.org/keys/nginx_signing.key | apt-key add -` },
        ],
        [
          { type: 'code', content: `nano /etc/apt/sources.list.d/nginx.list` },
          { type: 'text', content: `deb http://nginx.org/packages/ubuntu/ focal nginx` },
          { type: 'text', content: `deb-src http://nginx.org/packages/ubuntu/ focal nginx` },
        ],
        [
          { type: 'code', content: `apt update -y` },
        ],
        [
          { type: 'code', content: `cd /usr/local/src` },
        ],
        [
          { type: 'code', content: `apt source nginx` },
        ],
        [
          { type: 'code', content: `apt build-dep nginx -y` },
        ],
        [
          { type: 'code', content: `git clone --recursive https://github.com/google/ngx_brotli.git` },
        ],
        [
          { type: 'code', content: `cd /usr/local/src/nginx-*/` },
        ],
        [
          { type: 'code', content: `nano debian/rules` },
          { type: 'text', content: 'Найти блоки' },
          { type: 'text', content: 'config.env.nginx' },
          { type: 'text', content: 'config.env.nginx_debug' },
          { type: 'text', content: 'Добавить новый ключ в каждом ./configure' },
          { type: 'code', content: '--add-module=/usr/local/src/ngx_brotli' },
        ],
        [
          { type: 'code', content: `dpkg-buildpackage -b -uc -us` },
        ],
        [
          { type: 'code', content: `ls /usr/local/src/*.deb` },
        ],
        [
          { type: 'code', content: `dpkg -i /usr/local/src/*.deb` },
        ],
        [
          { type: 'text', content: 'nano /etc/nginx/nginx.conf' },
          { type: 'code', content: `user www-data;
worker_processes auto;
pid /var/run/nginx.pid;

events {
    worker_connections 768;
}

include /etc/nginx/sites-enabled/*.stream;

http {

    # Basic

    sendfile on;
    tcp_nopush on;
    tcp_nodelay on;
    types_hash_max_size 2048;
    server_tokens off;
    ignore_invalid_headers on;

    # Decrease default timeouts to drop slow clients

    keepalive_timeout 40s;
    send_timeout 20s;
    client_header_timeout 20s;
    client_body_timeout 20s;
    reset_timedout_connection on;

    # Hash sizes

    server_names_hash_bucket_size 64;

    # Mime types

    default_type  application/octet-stream;
    include /etc/nginx/mime.types;

    # Logs

    log_format main '$remote_addr - $remote_user [$time_local] "$request" $status $bytes_sent "$http_referer" "$http_user_agent" "$gzip_ratio"';
    access_log /var/log/nginx/access.log main;
    error_log /var/log/nginx/error.log warn;

    # Limits

    limit_req_zone  $binary_remote_addr  zone=dos_attack:20m   rate=30r/m;

    # Gzip

    gzip on;
    gzip_disable "msie6";
    gzip_vary off;
    gzip_proxied any;
    gzip_comp_level 5;
    gzip_min_length 1000;
    gzip_buffers 16 8k;
    gzip_http_version 1.1;
    gzip_types
        application/atom+xml
        application/javascript
        application/json
        application/ld+json
        application/manifest+json
        application/rss+xml
        application/vnd.geo+json
        application/vnd.ms-fontobject
        application/x-font-ttf
        application/x-web-app-manifest+json
        application/xhtml+xml
        application/xml
        font/opentype
        image/bmp
        image/svg+xml
        image/x-icon
        text/cache-manifest
        text/css
        text/plain
        text/vcard
        text/vnd.rim.location.xloc
        text/vtt
        text/x-component
        text/x-cross-domain-policy;

    # Brotli

    brotli on;
    brotli_comp_level 6;
    brotli_types
        text/xml
        image/svg+xml
        application/x-font-ttf
        image/vnd.microsoft.icon
        application/x-font-opentype
        application/json
        font/eot
        application/vnd.ms-fontobject
        application/javascript
        font/otf
        application/xml
        application/xhtml+xml
        text/javascript
        application/x-javascript
        text/$;

    # Virtual Hosts

    include /etc/nginx/sites-enabled/*;

    # Configs

    include /etc/nginx/conf.d/*.conf;
    include /usr/share/nginx/modules/*.conf;

}` },
        ],
        [
          { type: 'code', content: `nginx -t` },
        ],
        [
          { type: 'code', content: `systemctl start nginx` },
        ],
        [
          { type: 'code', content: `systemctl status nginx` },
        ],
        [
          { type: 'code', content: `mkdir /etc/systemd/system/nginx.service.d` },
        ],
        [
          { type: 'code', content: `printf "[Service]\nExecStartPost=/bin/sleep 0.1\n" > /etc/systemd/system/nginx.service.d/override.conf` },
        ],
        [
          { type: 'code', content: `systemctl daemon-reload` },
        ],
        [
          { type: 'code', content: `systemctl restart nginx` },
        ],
        [
          { type: 'code', content: `curl -H 'Accept-Encoding: br' -I http://localhost` },
          { type: 'text', content: 'Проверяем наличие <span class="accent">Content-Encoding: br</span>' },
        ],
        [
          { type: 'code', content: 'nano /etc/ufw/applications.d/nginx.ini' },
          { type: 'text', content: 'Туда ставим ↓' },
          { type: 'code', content: `[Nginx HTTP]
title=Web Server
description=Enable NGINX HTTP traffic
ports=80/tcp

[Nginx HTTPS]
title=Web Server (HTTPS)
description=Enable NGINX HTTPS traffic
ports=443/tcp

[Nginx Full]
title=Web Server (HTTP,HTTPS)
description=Enable NGINX HTTP and HTTPS traffic
ports=80,443/tcp` }
        ],
        [
          { type: 'code', content: `apt install ufw` },
        ],
        [
          { type: 'code', content: 'ufw app list' },
        ],
        [
          { type: 'code', content: 'ufw enable' },
        ],
        [
          { type: 'code', content: `ufw allow 'Nginx Full'` },
        ],
        [
          { type: 'code', content: `ufw allow 'OpenSSH'` },
        ],
        [
          { type: 'code', content: 'ufw status' },
        ],
        [
          { type: 'code', content: 'openssl dhparam -out /etc/ssl/certs/dhparam.pem 2048' },
        ],
        [
          { type: 'code', content: 'mkdir -p /etc/nginx/snippets/' },
        ],
        [
          { type: 'code', content: 'nano /etc/nginx/snippets/ssl-params.conf' },
          { type: 'text', content: 'Туда ставим ↓' },
          { type: 'code', content: `ssl_session_timeout 1d;
ssl_session_cache shared:SSL:10m;
ssl_session_tickets off;

ssl_dhparam /etc/ssl/certs/dhparam.pem;

ssl_protocols TLSv1.2 TLSv1.3;
ssl_ciphers ECDHE-ECDSA-AES128-GCM-SHA256:ECDHE-RSA-AES128-GCM-SHA256:ECDHE-ECDSA-AES256-GCM-SHA384:ECDHE-RSA-AES256-GCM-SHA384:ECDHE-ECDSA-CHACHA20-POLY1305:ECDHE-RSA-CHACHA20-POLY1305:DHE-RSA-AES128-GCM-SHA256:DHE-RSA-AES256-GCM-SHA384;
ssl_prefer_server_ciphers off;

add_header Strict-Transport-Security "max-age=63072000" always;` }
        ],
        [
          { type: 'code', content: 'timedatectl set-timezone UTC' },
        ],
        [
          { type: 'code', content: 'snap install core' },
        ],
        [
          { type: 'code', content: 'snap install --classic certbot' },
        ],
        [
          { type: 'code', content: 'ln -s /snap/bin/certbot /usr/bin/certbot' },
        ],
        [
          { type: 'code', content: 'mkdir -p /etc/nginx/sites-available/' },
        ],
        [
          { type: 'code', content: 'mkdir -p /etc/nginx/sites-enabled/' },
        ],
        [
          { type: 'code', content: `adduser ${this.username}` },
        ],
        [
          { type: 'code', content: `adduser ${this.username} sudo` },
        ],
        [
          { type: 'code', content: `nano /etc/ssh/sshd_config` },
          { type: 'text', content: 'И там ставим PermitRootLogin no' },
        ],
        [
          { type: 'code', content: `systemctl restart sshd` },
        ],
        [
          { type: 'code', content: `exit` },
        ],
        [
          { type: 'code', content: `ssh ${this.username}@${this.ip}` },
        ],
        [
          { type: 'code', content: `mkdir -p ~/.ssh` },
        ],
        [
          { type: 'code', content: `nano ~/.ssh/authorized_keys` },
        ],
        [
          { type: 'text', content: '<span style="color: #4dbfbf">Local Terminal</span>' },
          { type: 'code', content: `cat ~/.ssh/id_rsa.pub | pbcopy` },
        ],
        [
          { type: 'code', content: `sudo chmod -R 700 ~/.ssh/` },
        ],
        [
          { type: 'code', content: `sudo nano /etc/ssh/sshd_config` },
          { type: 'text', content: 'И там ставим PasswordAuthentication no' },
        ],
        [
          { type: 'code', content: `sudo systemctl restart sshd` },
        ],
        [
          { type: 'code', content: `sudo visudo` },
          { type: 'text', content: 'И там ставим %sudo   ALL=(ALL:ALL) NOPASSWD:ALL' },
        ],
        [
          { type: 'code', content: 'sudo mkdir /var/www' },
        ],
        [
          { type: 'code', content: 'sudo chown -R $USER:$USER /var/www/' },
        ],
        [
          { type: 'text', content: 'Если не нужен Backend, тут можно остановится :)' },
        ],
      ]
    }
  },
  methods: {
    async getIP() {
      const data = await fetch('https://api.ipify.org?format=json')
      const json = await data.json()
      return json.ip
    }
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/pages/nginx'

.action_result:focus, .action_result:focus-visible
  box-shadow: inset 0 0 8px 2px #000000c2
  border: 0

.action_result p
  color: #4dbf90
  font-size: 14px
  line-height: 28px
  padding-left: 0.3em
</style>